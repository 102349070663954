<template>
	<div>
		<div class="wrapper clearfix">
			<div class="joinus-title">岗位详情</div>
			<div class="joinus-list">
				<div class="joinus-list--item">
					<span class="joinus-label">岗位名称:</span>{{ detailInfo.jobDatailName }}
				</div>
				<div class="joinus-list--item">
					<span class="joinus-label">地点:</span>{{ detailInfo.addr }}
				</div>
				<div class="joinus-list--item">
					<div class="mb-md">
						<span class="joinus-label">需求人数:</span>{{ detailInfo.needNumber }}人
					</div>
				</div>
				<div class="joinus-list--item">
					<div class="joinus-label">描述:</div>
					<div v-html="detailInfo.describe"></div>
					<!-- {{ detailInfo.describe }} -->
					<!-- 1.对量子电路及算法进行经典仿真；<br>
					2.应用现有的量子算法，模拟与求解某些实际问题；<br>
					3.针对具体应用开发新的量子算法。 -->
				</div>
				<div class="joinus-list--item">
					<div class="joinus-label">要求:</div>
					<div v-html="detailInfo.requirement"></div>
					<!-- {{ detailInfo.requirement }} -->
					<!-- 1.本硕985院校，本科是物理/数学/计算机等相关专业，硕博须是量子计算相关专业；<br>
					2.熟悉Python、C++等程序设计语言，有较强的编程基础；<br>
					3.有量子计算软件开发背景者优先。 -->
				</div>
				<div class="joinus-list--item">
					<div class="mb-md">
						<span class="joinus-label">咨询电话:</span>{{ detailInfo.consultationHotline }}
					</div>
				</div>
				<div class="joinus-list--item">
					<div class="mb-md">
						<span class="joinus-label">待遇:</span>{{ detailInfo.startSalaryStr }}&nbsp;-&nbsp;{{ detailInfo.endSalaryStr
						}}&nbsp;&nbsp;
						{{
							detailInfo.bonus }}薪<span v-if="detailInfo.internshipStatus">&nbsp;&nbsp;&nbsp;&nbsp;实习：{{
		detailInfo.internshipSalaryStartStr }}&nbsp;-&nbsp;{{
		detailInfo.internshipSalaryEndStr }}</span>
					</div>
					在微观纪元，你将收货丰厚的待遇，带薪年假，年度健康体检，多样茶歇零食，节日福利，良好的职业发展前景，深谙行业的核心团队，轻松的工作氛围，高额的伯乐奖
				</div>

			</div>
		</div>
		<div class="wrapper-form">
			<div class="wrapper clearfix">
				<div class="joinus-form-title">
					开始投递
				</div>
				<form ref="form">
					<div class="form-item flex-row">
						<div class="form-label">求职者姓名</div>
						<div class="flex-1">
							<v-text-field outlined required hide-details="auto" ref="jobSeeker" v-model="jobSeeker"
								:rules="[() => !!jobSeeker || '请输入求职者姓名']"></v-text-field>
						</div>
					</div>
					<div class="form-item flex-row">
						<div class="form-label">联系方式</div>
						<div class="flex-1">
							<v-text-field outlined required hide-details="auto" ref="email" v-model="email"
								:rules="[() => !!email || '请输入联系方式']"></v-text-field>
						</div>
					</div>
					<div class="form-item flex-row">
						<div class="form-label">求职岗位</div>
						<div class="flex-1">
							<v-select :items="options" item-value="id" item-text="jobDatailName" outlined required hide-details="auto"
								ref="jobId" v-model="jobId" :rules="[() => !!jobId || '请选择求职岗位']"></v-select>
						</div>
					</div>
					<div class="form-item flex-row">
						<div class="form-label">上传简历</div>
						<div class="flex-1">
							<v-file-input multiple outlined required prepend-icon="" @change="handleFileChange" ref="file"
								v-model="file" accept=".doc, .docx, .ppt, .pptx, .bmp, .gif, .jpg, .jpeg, .png, .pdf"
								:messages="['附件仅限word, pdf, ppt, pptx, bmp, gif, png, jpeg, jpg格式文件,最多3个文件']"
								:rules="[() => file.length > 0 || '请上传简历']"></v-file-input>
						</div>
					</div>
					<div class="form-item flex-row justify-center">
						<v-btn depressed style="font-size: 24px;" @click="onSubmit()" :loading="loading">
							提交
						</v-btn>
					</div>
				</form>
			</div>
		</div>
		<v-snackbar v-model="snackbar" :timeout="timeout">
			{{ text }}

			<template v-slot:actions>
				<v-btn color="blue" variant="text" @click="snackbar = false">
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import { uploadFile, getJobDetails, getJobList, submitJob } from "@/api/index"
export default {
	data() {
		return {
			model: '',
			queryParams: {
				pageNum: 1,
				pageSize: 9999,
			},
			options: [
				{ name: 'xxx', id: '1' },
				{ name: 'xxx2', id: '12' }
			],
			jobSeeker: '',
			jobId: '',
			email: '',
			file: [],
			formHasErrors: false,
			detailInfo: {},
			snackbar: false,
			text: '',
			timeout: 2000,
			loading: false
		}
	},
	computed: {
		form() {
			return {
				jobSeeker: this.jobSeeker,
				email: this.email,
				jobId: this.jobId,
				file: this.file,
			}
		}
	},
	methods: {
		// 获取岗位详情
		async getDetails(id) {
			let res = await getJobDetails(id);
			if (res.code == 200) {
				this.detailInfo = res.data
			}
		},
		// 获取岗位列表
		getJobData() {
			getJobList(this.queryParams).then(result => {
				if (result.code === 200) {
					this.options = result.rows
				}
			});
		},
		onSubmit() {
			this.formHasErrors = false
			let canCommit = true
			Object.keys(this.form).forEach(f => {
				if (!this.form[f]) this.formHasErrors = true
				if (this.form[f] === '' || this.form[f].length < 1) canCommit = false
				this.$refs[f].validate(true);
			})
			// 表单校验通过
			if (canCommit) {
				this.loading = true
				// 上传文件
				this.fileChange()
			}
		},
		async fileChange() {
			let formData = new FormData();

			this.file.map(item => {
				formData.append('files', item);
			})
			let res = await uploadFile(formData);
			if (res.code === 200) {
				this.form.file = res.mailFilenames
				this.postJob()
			} else {
				this.snackbar = true
				this.text = "简历上传失败"
			}
		},
		postJob() {
			submitJob(this.form).then(result => {
				if (result.code === 200) {
					this.snackbar = true
					this.text = "投递成功"
					this.loading = false
				} else {
					this.snackbar = true
					this.text = result.msg
					this.loading = false
				}
			});
		},
		handleFileChange() {
			if (this.file.length > 3) {
				this.file = []
				this.snackbar = true
				this.text = "文件数量最多3个"
			}
		}
	},
	mounted() {
		this.jobId = parseInt(this.$route.params.id)
		this.getJobData()
		this.getDetails(this.$route.params.id)
	}
}
</script>

<style scoped lang="scss">
  @import url('./quill.snow.css');
.wrapper {
	max-width: 800px;
	margin: 0 auto;
	padding: 100px 0;
}

.wrapper-form {
	background-color: #EFEFEF;
}

.joinus-title {
	line-height: 50px;
	font-size: 3.6vw;
	text-align: center;
	margin-bottom: 3.6vw;
}

.joinus-form-title {
	font-size: 3.6vw;
	margin-bottom: 3.6vw;
}

.joinus-list {
	font-size: 20px;
}

.joinus-list--item {
	margin-bottom: 3.6vw;
}

.joinus-label {
	font-weight: bold;
}

span.joinus-label {
	margin-right: 10px;
}

div.joinus-label {
	margin-bottom: 10px;
}

.form-item {
	margin-bottom: 3.6vw;
}

.form-label {
	width: 15vw;
	height: 56px;
	line-height: 56px;
	font-size: 2.25vw;
}

.form-label,
.joinus-form-title {
	font-family: ClumsyRegular;
}

::v-deep {
	.v-snack__wrapper {
		min-width: 100px
	}

	.v-snack__content {
		font-size: 0.275rem;
		line-height: 0.4rem;
	}
}
</style>